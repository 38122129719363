<script lang="ts" setup>
import { Atropos } from 'atropos/vue'
import { Iconify } from '~/types/assets'

const { height } = useWindowSize()
const imgSize = ref('500px')

onMounted(() => {
  imgSize.value = height.value ? (height.value / 2).toString() : '500px'
})
</script>

<template lang="pug">
section.home__alpha
  KinesisContainer.home__alpha__wrapper
    .home__alpha__wrapper__atropos
      Atropos(
        :rotate="true"
        :active-offset="40"
        :shadow-scale="1.05"
        :highlight="true"
      )
        .home__alpha__wrapper__atropos__content
          figure
            picture
              source(
                media="(max-width: 700px)",
                srcset="https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/xs.webp"
                type="image/webp"
              )
              source(
                media="(max-width: 850px)",
                srcset="https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/md.webp"
                type="image/webp"
              )
              source(
                media="(max-width: 2200px)",
                srcset="https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/lg.webp"
                type="image/webp"
              )
              source(
                media="(max-width: 5000px)",
                srcset="https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/xl.webp"
                type="image/webp"
              )
              NuxtImg(
                src="/portfolio.rottigni.tech/static/images/hero_profile/lg.webp",
                sizes="(max-width: 700px) 100vw, (max-width: 850px) 50vw, (max-width: 2200px) calc(100vw / 3), 50vw"
                :width="imgSize"
                :height="imgSize"
                provider="s3"
                legacy-format="png",
                preset="hero",
                format="webp",
                :quality="80"
                :alt="$t('home.alpha.image.alt')",
                :longdesc="$t('home.alpha.image.longdesc')"
                itemprop="image"
                title="Lorenzo Rottigni"
                :aria-label="$t('home.alpha.image.aria-label')"
                itemscope
                item-type="https://schema.org/ImageObject"
                data-test="hero-image"
              )
            figcaption {{ $t('home.alpha.image.figcaption') }}
          .home__alpha__wrapper__atropos__content__circle
            IconCircle
    KinesisElement.home__alpha__wrapper__content(
      type="translate"
      axis="x"
      :strength="50"
    )
      .home__alpha__wrapper__content__scroller
        .hgroup
          h1 {{ $t('home.alpha.title') }}
          h2 {{ $t('home.alpha.subtitle') }}
        .home__alpha__wrapper__content__intro
          article(
            datetime="2022-12-8"
          )
            p {{ $t('home.alpha.text-content.0') }}
            p {{ $t('home.alpha.text-content.1') }}
          aside
            p {{ $t('home.alpha.text-content.2') }}
</template>

<style lang="sass" scoped>
section.home__alpha
  @apply w-full h-full flex items-center justify-center
  .home__alpha__wrapper
    @apply w-full h-full flex-center flex-col md:flex-row mt-14 md:mt-0
    .home__alpha__wrapper__atropos
      @apply w-full xl:w-6/12 lg:-mr-[4vw] 2xl:-mr-[8vw]
      :deep(.atropos)
        .atropos-inner
          @apply border-t-2 border-b-2 border-primary bg-secondary-o-50 rounded-[50%] w-[37.5vh] h-[37.5vh] xl:w-[50vh] xl:h-[50vh] overflow-visible mx-auto
      .home__alpha__wrapper__atropos__content
        @apply w-full h-full relative
        img
          @apply w-full h-full object-cover object-center rounded-[50%] absolute top-0 right-0 left-0 bottom-0 z-20 border-4 border-primary shadow-2xl
        figure figcaption
          @apply opacity-0
        .home__alpha__wrapper__atropos__content__circle
          @apply absolute -top-20 -left-20 -right-20 -bottom-20 animate-spin-wheel-alt z-10
    .home__alpha__wrapper__content
      @apply w-full xl:w-6/12 overflow-hidden relative z-10 lg:z-30 lg:border-2 border-primary lg:bg-[rgba(0,0,0,0.75)] lg:rounded-r-[32.5%]
      .home__alpha__wrapper__content__scroller
        @apply w-full lg:h-[48vh] xl:h-[55vh] flex flex-col items-start justify-start lg:pl-14 lg:pr-4 xl:px-20 py-3 shadow-2xl overflow-hidden lg:pb-8
        .hgroup
          @apply w-full lg:border-l-[0.75rem] border-primary lg:pl-4 md:py-2 mt-4 lg:mt-2
          h1
            @apply w-full text-primary font-bold text-4xl xl:text-5xl text-center lg:text-left text-animated
          h2
            @apply w-full text-secondary text-xl md:text-xl xl:text-3xl md:mt-2 font-thin text-center lg:text-left mt-2
        .home__alpha__wrapper__content__intro
          @apply mt-6 hidden md:block h-[600px] overflow-y-auto scrollbar-primary
          article
            p
              @apply w-full text-white text-sm lg:text-sm xl:text-lg text-center md:text-left max-w-[500px]
          aside
            @apply hidden lg:block mt-6
            p
              @apply w-full text-secondary text-sm xl:text-base max-w-[500px]

@media screen and (min-width: 768px)
  section.home__alpha .home__alpha__wrapper .home__alpha__wrapper__content
    clip-path: polygon(100% 10%, 100% 90%, 100% 100%, 0 100%, 0 85%, 10% 70%, 10% 30%, 0 15%, 0 0, 100% 0)
</style>
