<script lang="ts" setup>
import { useMq } from 'vue3-mq'
import type { LordIcon } from '~/types/assets'
import { LordIconSource, LordIconTrigger, Iconify } from '~/types/assets'
import { useLocaleThemePath } from '~/composables/locale'

import { useRoot } from '~/store'
import { timeline as _timeline } from '~/config/timeline'

const { t } = useI18n()
const { xs, sm } = useMq()
const { theme } = storeToRefs(useRoot())

const timelineEl: Ref = ref(null)
// necessary for SSR
const isMobile = ref(false)
const nuxtLink = resolveComponent('NuxtLink')

const downKeyIcon: LordIcon = {
  src: LordIconSource?.downKey,
  trigger: LordIconTrigger?.hoverLoop,
  revert: true,
  delay: 500,
  loading: 'lazy',
  size: 65
}

const upKeyIcon: LordIcon = {
  src: LordIconSource?.upKey,
  trigger: LordIconTrigger?.hoverLoop,
  revert: true,
  delay: 500,
  loading: 'lazy',
  size: 65
}

const timeline = computed(() => _timeline(t))

function scrollBottom() {
  if (timelineEl.value?.clientHeight)
    timelineEl.value.scrollBy({
      top: timelineEl.value.clientHeight * 12,
      left: 0,
      behavior: 'smooth'
    })
}

function scrollTop() {
  timelineEl.value?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

function play() {
  if (timelineEl.value?.clientHeight)
    timelineEl.value.scrollBy({
      top: timelineEl.value.clientHeight * 12,
      left: 0,
      behavior: 'smooth'
    })
  setTimeout(() => timelineEl.value?.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 1200)
}

onMounted(() => {
  isMobile.value = xs || sm || false
  scrollTop()
})
</script>

<template lang="pug">
section.home__epsilon
  h6 {{ $t('home.epsilon.timeline.title') }}
  .home__epsilon__timeline__player
    span(@click="scrollTop")
      IconLord(v-bind="upKeyIcon")
    span(@click="play")
      Icon(:name="Iconify?.play" class="hover:rotate-[360deg]")
    span(@click="scrollBottom")
      IconLord(v-bind="downKeyIcon")
  .home__epsilon__timeline(ref="timelineEl")
    ul(:style="`height: calc(${isMobile ? '55vh' : '25vh'} * ${timeline.length})`")
      li(
        v-for="(event, index) in timeline"
        :key="`timeline-event-${index}`"
        :style="`top: calc((100% / ${timeline?.length}) * ${index})`"
        :class="(isMobile) ? 'right' : (index % 2 === 0) ? 'left' : 'right'"
      )
        component(
          :is=" \
            event.anchor?.to \
            ? nuxtLink \
            : event.anchor?.href ? 'a' : 'article' \
          "
          v-bind="event?.anchor ? event.anchor : {}"
        )
          .home__epsilon__time__event__link
            time(:datetime="event.date") {{ event.date }}
            .gear
              Icon(:name="Iconify?.gear")
          .home__epsilon__time__event__content
            component(:is="event.logo")
            span.title {{ event.title }}
            p(v-if="event.description" v-html="event.description")
</template>

<style lang="sass" scoped>
section.home__epsilon
  @apply w-full relative
  h6
    @apply w-full text-center text-primary text-2xl xl:text-3xl font-bold whitespace-nowrap mt-28 mb-6 text-animated
  .home__epsilon__timeline
    @apply w-full h-[75vh] overflow-y-auto overflow-x-hidden flex items-start justify-end lg:justify-center text-primary pt-16 lg:pt-24 pb-48 lg:pb-80 relative
    scrollbar-width: none
    -ms-overflow-style: none
    &::-webkit-scrollbar
      @apply hidden
    ul
      @apply w-4 bg-gradient-theme-1 translate-x-[50%] relative mr-6 lg:mr-0 rounded-b-[50px]
      li
        @apply absolute w-[280px] xl:w-[350px] 2xl:w-[420px] cursor-pointer
        article,
        a
          @apply w-full flex justify-between no-underline
          span.title
            @apply whitespace-normal text-base font-bold text-primary
          p
            @apply text-xs text-white mt-2 max-h-[170px] lg:max-h-full overflow-y-auto scrollbar-primary
          .home__epsilon__time__event__content
            @apply w-full p-4 border-t border-primary bg-transparent-dark hover:bg-dark-800 flex flex-col items-start transition-all
            span.iconify
              @apply text-6xl text-secondary
          .home__epsilon__time__event__link
            @apply w-14 xl:w-28 2xl:w-36 h-3 bg-primary flex-shrink-0 relative
            .gear
              @apply absolute w-12 h-12 rounded-[50%] bg-dark-900 border-2 border-dashed border-secondary z-10 mt-1 flex-center
              span.iconify
                @apply text-[2.5rem] animate-spin text-secondary
            time
              @apply absolute left-[-50%] lg:left-[50%] top-0 whitespace-nowrap text-secondary

        // &:nth-child(odd)
        &.left
          @apply left-0
          .home__epsilon__time__event__content
            @apply border-l rounded-br-2xl order-2
          .home__epsilon__time__event__link
            @apply order-1
            .gear
              @apply left-0 ml-2
              transform: translate(-50%, -50%)
            time
              transform: translate(0%, -150%)
        // &:nth-child(even)
        &.right
          @apply right-0 mr-2
          .home__epsilon__time__event__content
            @apply border-r rounded-bl-2xl order-1
          .home__epsilon__time__event__link
            @apply order-2
            .gear
              @apply right-0
              transform: translate(50%, -50%)
            time
              transform: translate(-100%, -150%)
  .home__epsilon__timeline__player
    @apply absolute right-0 top-[50%] translate-y-[-50%] hidden lg:flex-center flex-col border border-primary rounded-2xl bg-black z-10 text-primary
    span
      @apply cursor-pointer
      & > *
        @apply transition-all
// @media screen and (min-width: 700px)
//   section.home__epsilon .home__epsilon__timeline ul li:nth-child(odd)
//     @apply left-0
//     .home__epsilon__time__event__content
//       @apply border-l rounded-br-2xl
//     .home__epsilon__time__event__link
//       &::after
//         @apply left-0
//         transform: translate(-25%, -50%)
</style>
