<!-- eslint-disable vue/one-component-per-file -->
<script lang="ts" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import { Atropos } from 'atropos/vue'
import type { LordIcon } from '~/types/assets'
import { LordIconSource, LordIconTrigger } from '~/types/assets'
import type { List } from '~/types/custom'
import { useLocaleThemePath } from '~/composables/locale'
import IconLord from '~/components/icon/Lord.vue'
import { services } from '~/config/services'

const { xs } = useMq()
const { t } = useI18n()

// necessary for SSR
const isMobile = ref(false)

onMounted(() => {
  if (xs) isMobile.value = true
})
</script>

<template lang="pug">
section.home__delta
  h5 {{ $t('home.delta.title') }}
  ul(v-if="!isMobile")
    li(
      v-for="(item, index) in services(t,isMobile)"
      :key="`list-item-${index}`"
    )
      Atropos(
        :rotate="true"
        :active-offset="40"
        :shadow-scale="1.05"
      )
        .atropos__wrapper
          span {{ item.title }}
          Component(
            :is="item.iconComponent"
            v-if="item?.iconComponent"
          )
          p {{ item.textContent }}
  ul(v-else)
    li(
      v-for="(service, index) in services(t,isMobile)"
      :key="`service-${index}`"
    )
      span {{ service.title }}
      Component(
        :is="service.iconComponent"
        v-if="service?.iconComponent"
      )
      p {{ service.textContent }}
</template>

<style lang="sass" scoped>
.home__delta
  @apply w-full h-full flex-center flex-col md:px-8
  h5
    @apply text-primary text-2xl xl:text-4xl mb-6 text-center text-animated font-bold
  ul
    @apply w-full lg:w-10/12 grid grid-cols-4 lg:grid-cols-5 place-content-center gap-2 md:gap-4 justify-center
    li
      @apply cursor-pointer flex-center flex-col
      filter: drop-shadow(0 0 3rem #525252)
      .atropos__wrapper
        @apply w-full h-full flex-center flex-col p-2 text-white border border-primary text-center bg-dark-900 rounded-md text-sm lg:text-base
      .atropos
        @apply h-full
      span
        @apply text-white xl:text-secondary text-sm text-center
      p
        @apply hidden xl:block text-white text-[11px] leading-[13px] text-center
</style>
